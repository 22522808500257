import { IconChevronDown, IconChevronUp, IconX } from "@tabler/icons-react";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
//
import { ISelect, SelectOption } from "./select";
//
import "./Select.scss";

export const Select = forwardRef(({
    placeholder = "Seleccionar...",
    options,
    onChange,
    onType,
    className = "",
    name = "",
    value: option,
    label,
    disabled,
    emptyOptionsLabel = "No hay opciones disponibles",
    style,
    variant = "normal",
    readOnly,
    inputGroup,
    width,
    isSmall,
    isCleanable,
    isSearchable,
    maxHeight = 200,
    clearOnSelect,
    showDefaultOption = true,
    optionColor,
    autoFocus,
    changeToInput,
    inputValue,
    isLoading,
}: ISelect, ref) => {
    const menuRef = useRef<HTMLDivElement>(null);

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [searchableText, setSearchableText] = useState<string>("");
    const [stateOptions, setStateOptions] = useState<SelectOption[]>([]);
    const [selectedOption, setSelectedOption] = useState<SelectOption>({
        label: "",
        value: ""
    });
    const [selectCounter, setSelectCounter] = useState<number>(0);
    const selectRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, () => ({
        clearInput: handleCleanInput
    }));

    useEffect(() => {
        if (options && options.length) {
            setStateOptions(options);
        } else {
            setStateOptions([]);
        }
    }, [options]);

    useEffect(() => {
        if (option && String(option?.value).length) {
            setSelectedOption(option);
            setSearchableText(option.label);
        } else {
            setSelectedOption({ value: "", label: "" });
            setSearchableText("");
        }
    }, [option]);

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
                setIsMenuOpen(false);
            }
        };
        isMenuOpen && document.addEventListener("click", handleOutsideClick);
        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, [selectRef, setIsMenuOpen, isMenuOpen]);

    useEffect(() => {
        if (isMenuOpen && options) {
            const index = options?.map(item => item.value).indexOf(selectedOption.value);
            const menuHeight = parseInt(menuRef.current?.style.maxHeight || "", 10);
            const maxItems = Math.floor(menuHeight / 28) - 1;

            menuRef.current?.children[index + 1]?.scrollIntoView({ block: "nearest", inline: "start" });
            if ((index + 1) > maxItems) {
                setSelectCounter(maxItems);
            } else {
                setSelectCounter(index + 1);

            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMenuOpen, options]);

    const handleToggle = () => {
        if (!readOnly) {
            setIsMenuOpen(!isMenuOpen);
            inputRef.current?.focus();
        }
    };

    const handleSelectOption = (option: SelectOption) => {
        onChange?.({ option, name });
        if (!clearOnSelect) {
            setSearchableText(option.label);
            setSelectedOption(option);
        } else {
            setSearchableText("");
            setSelectedOption({
                label: "",
                value: ""
            });
        }
        setSelectCounter(0);
    };

    const handleCleanInput = () => {
        const cleanOption = { label: "", value: "" };
        onChange?.({ name: "", option: cleanOption });
        setSearchableText("");
        setSelectedOption(cleanOption);
        setStateOptions(options as SelectOption[]);
        setSelectCounter(0);
    };

    const handleOnSearch = (value: string) => {
        const cleanOption = { label: "", value: "", inputText: searchableText };
        if (value.length < selectedOption.label.length) {
            onChange?.({ name: "", option: cleanOption });
            setSelectedOption(cleanOption);
            return;
        }
        if (value.length) {
            setIsMenuOpen(true);
        }

        const newOptions = options?.filter(opt => opt.label.toLowerCase().includes(value.toLowerCase()));
        setSearchableText(value);
        onType?.(value);
        setStateOptions(newOptions as SelectOption[]);

    };

    const handleSelectWithArrows = (event: React.KeyboardEvent) => {

        if (!disabled && stateOptions && !readOnly) {
            const index = stateOptions.map(item => item.value).indexOf(selectedOption.value);
            const menuHeight = parseInt(menuRef.current?.style.maxHeight || "", 10);
            const maxItems = Math.floor(menuHeight / 28) - 1;

            if (event.key === "ArrowUp") {
                event.preventDefault();
                setIsMenuOpen(true);
                setSelectCounter((val) => val - 1);
                setSearchableText(stateOptions[index - 1]?.label || "");
                setSelectedOption(stateOptions[index - 1] || { label: "", value: "" });
                if (selectCounter === 0) {
                    menuRef.current?.scrollBy(0, -28);
                    setSelectCounter(0);
                }
            }

            if (event.key === "ArrowDown") {
                event.preventDefault();
                setIsMenuOpen(true);
                setSelectCounter((val) => val + 1);
                if (!stateOptions[index + 1]?.value) {
                    menuRef.current?.scrollTo(0, 0);
                    setSelectCounter(1);
                }

                setSearchableText(stateOptions[index + 1]?.label || "");
                setSelectedOption(stateOptions[index + 1] || { label: "", value: "" });

                if (selectCounter === maxItems && stateOptions[index + 1]?.value) {
                    menuRef.current?.scrollBy(0, 28);
                    setSelectCounter(maxItems);
                }

                if (index === -1) {
                    menuRef.current?.scrollTo(0, 0);
                    setSelectCounter(1);
                }
            }

            if (event.key === "Enter") {
                handleSelectOption({ ...selectedOption, inputText: searchableText });
                setIsMenuOpen(false);
                setSelectCounter(0);
            }

            if (event.key === "Escape") {
                setIsMenuOpen(false);
                setSelectCounter(0);
                setSelectedOption({ label: "", value: "" });
                setSearchableText("");
            }
        }
    };



    const renderSelect = () => {
        return <div className="d-flex flex-column" style={{ width: width || "fit-content" }}>
            {label && <label className="text-secondary ms-1">{label}</label>}
            <div
                className={`select ${className} select-${variant} ${isSmall ? "select-small" : ""} ${disabled ? "select-disabled" : ""} ${inputGroup ? "grouped" : ""}`}
                onClick={handleToggle}
                ref={selectRef}
            >
                <input
                    ref={inputRef}
                    type="text"
                    className="select-input"
                    placeholder={placeholder}
                    autoFocus={autoFocus}
                    style={{
                        ...style,
                        color: optionColor ? selectedOption.color : (selectedOption.value || searchableText)
                            ? "inherit"
                            : disabled ? "#212529" : "#999",
                        cursor: isSearchable ? "text" : "pointer",
                    }}
                    value={isSearchable
                        ? searchableText
                        : selectedOption.label?.length
                            ? selectedOption.label
                            : (option && String(option.value).length && selectedOption.label)
                                ? option.label
                                : ""}
                    onChange={({ target }) => (isSearchable && !readOnly) ? handleOnSearch(target.value) : undefined}
                    onKeyDown={(event) => handleSelectWithArrows(event)}
                    onBlur={() => setSelectCounter(0)}
                    onFocus={() => {
                        if (options) {
                            const index = options.map(item => item.value).indexOf(selectedOption.value);
                            menuRef.current?.children[index]?.scrollIntoView({ block: "nearest", inline: "start" });
                        }
                    }}
                    readOnly={!isSearchable && readOnly}
                />
                {(selectedOption.value && isCleanable) && (
                    <div className="ms-2" onClick={() => handleCleanInput()}>
                        <IconX />
                    </div>
                )}
                {isLoading ?
                    <div className={`${isLoading ? "isLoadingSelect" : ""}`}></div>
                    :
                    <div onClick={handleToggle} style={{ pointerEvents: "none" }}>
                        {!isMenuOpen ? <IconChevronDown /> : <IconChevronUp />}
                    </div>
                }
                {!disabled && !readOnly && isMenuOpen && (
                    <div ref={menuRef} className="select-menu" style={{ maxHeight }}>
                        {!disabled && showDefaultOption &&
                            <div
                                className={`select-menu__item ${selectedOption.value === "" ? "selected" : ""}`}
                                onClick={() => handleSelectOption({ value: "", label: "" })}
                            >
                                Seleccionar...
                            </div>
                        }
                        {!disabled && stateOptions?.length ? stateOptions.map((option, index) => (
                            <div
                                key={`${option.label}-${index}`}
                                className={`select-menu__item ${selectedOption.value === option.value ? "selected" : ""}`}
                                onClick={() => handleSelectOption(option)}
                                tabIndex={index}
                                style={{ color: optionColor ? option.color : "" }}
                                title={option.label}
                            >
                                {option.label}
                            </div>
                        )) : (
                            <div className="select-menu__item">{emptyOptionsLabel}</div>
                        )}
                    </div>
                )}
            </div>
        </div>;
    };
    const renderInput = () => {
        return <div className="d-flex flex-column" style={{ width }}>
            {label && <label className="text-secondary ms-1">{label}</label>}
            <div
                className={`select ${className} select-${variant} ${isSmall ? "select-small" : ""} ${disabled ? "select-disabled" : ""} ${inputGroup ? "grouped" : ""}`}
                ref={selectRef}
            >
                <input
                    ref={inputRef}
                    type="text"
                    className="select-input"
                    placeholder={placeholder}
                    autoFocus={autoFocus}
                    style={{
                        ...style,
                        color: optionColor ? selectedOption.color : (selectedOption.value || searchableText)
                            ? "inherit"
                            : disabled ? "#212529" : "#999",
                        cursor: isSearchable ? "text" : "pointer",
                    }}
                    value={inputValue}
                    readOnly={!isSearchable && readOnly}
                />
            </div>
        </div>;
    };

    return changeToInput ? renderInput() : renderSelect();

});