import {
    Adaptation,
    Cie10EXTDiagnostic
} from "@/models/historyDetails/adaptation";
import { formatHistoryDetails } from "@/utils";
//
import { Segments } from "@/models/generalFields";
import { AdaptationPayload, ClearenceCentral, ContactLenses, GlassesToOrder, Overfraction, ScleralLenses, } from "@/models/sheets/adaptation";
import "../../../Adaptation/Adaptation.scss";
import CentralClearance from "../../../Adaptation/CentralClearance/CentralClearance";
import "../Details.scss";

export default function AdaptationDetails({ adaptationDetail, forDownload }: { adaptationDetail: AdaptationPayload; forDownload?: boolean; }) {

    const renderAttentionOrigin = (attentionOrigin: Segments | null) => {

        const motiveIss = attentionOrigin?.predefinedTxts ? attentionOrigin?.predefinedTxts?.find(t=> t.prefix === "current_disease") : {predifinedText: []};
        const motiveReason = attentionOrigin?.predefinedTxts ? attentionOrigin?.predefinedTxts?.find(t=> t.prefix === "reason_attention") : { predifinedText: []};


        if (attentionOrigin) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Origen de atención
                    </h4>
                    {attentionOrigin?.generalFields?.attentionOrigin !== null ? (
                        <h5>
                            <span className="fw-bold">Origen de la atención: </span>{" "}
                            {attentionOrigin?.generalFields?.attentionOrigin?.name}
                        </h5>
                    ) : ""}
                    {attentionOrigin.generalFields.abserveEvent !== null ? (
                        <h5>
                            <span className="fw-bold">Evento adverso: </span>{" "}
                            {attentionOrigin.generalFields.abserveEvent === 1 ? "SÍ" : "NO"}
                        </h5>
                    ) : ""}

                    <>
                        <h5 className="fw-bold mt-3">
                                Motivo consulta:
                        </h5> 

                        <h5>{motiveReason?.predifinedText.map(x=> x.name).join(", ")}</h5>
                            

                        <h5>{attentionOrigin.reasonForConsultingJst}</h5>
                    </>
                    <>
                        <h5 className="fw-bold mt-3">
                                Enfermedad actual:
                        </h5>
                        <h5>{motiveIss?.predifinedText.map(x=> x.name).join(", ")}</h5>

                        <h5>{attentionOrigin.currentDiseaseJst || ""}</h5>
                    </>
                    {attentionOrigin.generalFields.evolutionTime ? (
                        <h5>
                            <span className="fw-bold">Tiempo de evolución: </span>{" "}
                            {attentionOrigin.generalFields.evolutionTime} - {attentionOrigin.generalFields.evolutionTimeUnit?.name}
                        </h5>
                    ) : ""}
                </div>
            );
        } else {
            return "";
        }
    };

    const renderContactLenses = (contactLenses: ContactLenses) => {
        if (contactLenses) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Lentes de contacto prueba
                    </h4>
                    <h5 className="text-muted mb-3">
                        <>
                            <div className=""> <b>Diseño</b> {contactLenses.design}</div>
                            <div className=""> <b>Lentes</b> {contactLenses.lents}</div>
                            <div className=""> <b>Material</b> {contactLenses.materials}</div>
                            <div className=""> <b>Tipo</b> {contactLenses.type}</div>
                        </>
                    </h5>
                </div>
            );
        } else return "";
    };

    const renderOverfraction = (overfraction: Overfraction | null) => {
        if (overfraction) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Sobre-refracción
                    </h4>
                    <h5 className="text-muted">
                        {overfraction.od ? (
                            <>
                                <>
                                    <div className="fw-bold">OD: </div>
                                    <div><b> Esfera:</b> {overfraction.od.sphere} </div>
                                    <div><b>Cilindro OD:</b> {overfraction.od.cylinder} </div>
                                    <div><b>Eje OD:</b> {overfraction.od.axis} </div>
                                    <div><b>ADD OD:</b> {overfraction.od.add} </div>
                                    <div><b>AV OD:</b> {overfraction.od.visionShoot} </div>
                                    <br />

                                </>
                            </>
                        ) : ""}
                        {(overfraction.od && overfraction.oi) ? " - " : ""}
                        {overfraction.oi ? (
                            <>
                                <div className="fw-bold">OI: </div>
                                <div><b> Esfera:</b> {overfraction.oi.sphere} </div>
                                <div><b>Cilindro OI:</b> {overfraction.oi.cylinder} </div>
                                <div><b>Eje OI:</b> {overfraction.oi.axis} </div>
                                <div><b>ADD OI:</b> {overfraction.oi.add} </div>
                                <div><b>AV OI:</b> {overfraction.oi.visionShoot} </div>
                                <br />
                            </>
                        ) : ""}
                    </h5>
                </div>
            );
        } else return "";
    };

    const renderAdaptation = (adaptation: Adaptation | null) => {
        if (adaptation) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Adaptación
                    </h4>
                    {adaptation.tolerance ? (
                        <h5 className="text-muted mb-3">
                            <span className="fw-bold">Tolerancia: </span>
                            {adaptation.tolerance}
                        </h5>
                    ) : ""}
                    {adaptation.centered ? (
                        <h5 className="text-muted mb-3">
                            <span className="fw-bold">Centrado: </span>
                            {adaptation.centered}
                        </h5>
                    ) : ""}
                    {adaptation.motion ? (
                        <h5 className="text-muted mb-3">
                            <span className="fw-bold">Movimiento: </span>
                            {adaptation.motion}
                        </h5>
                    ) : ""}
                </div>
            );
        } else return "";
    };

    const renderGlassesToOrder = (glassesToOrder: GlassesToOrder) => {
        if (glassesToOrder) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Lentes a pedir
                    </h4>
                    <h5 className="text-muted">
                        {glassesToOrder.od ? (
                            <>
                                <div className="fw-bold">OD: </div>
                                <div><b> Esfera:</b> {glassesToOrder.od.sphere} </div>
                                <div><b>Cilindro OD:</b> {glassesToOrder.od.cylinder} </div>
                                <div><b>Eje OD:</b> {glassesToOrder.od.axis} </div>
                                <div><b>ADD OD:</b> {glassesToOrder.od.add} </div>
                                <div><b>AV OD:</b> {glassesToOrder.od.visionShoot} </div>
                                <br />

                            </>

                        ) : ""}
                        {glassesToOrder.oi ? (
                            <>
                                <div className="fw-bold">OI: </div>
                                <div><b> Esfera:</b> {glassesToOrder.oi.sphere} </div>
                                <div><b>Cilindro OI:</b> {glassesToOrder.oi.cylinder} </div>
                                <div><b>Eje OI:</b> {glassesToOrder.oi.axis} </div>
                                <div><b>ADD OI:</b> {glassesToOrder.oi.add} </div>
                                <div><b>AV OI:</b> {glassesToOrder.oi.visionShoot} </div>
                                <br />

                            </>
                        ) : ""}
                    </h5>
                    {glassesToOrder.lensType ? (
                        <h5 className="text-muted mb-4">
                            <span className="fw-bold">Tipo de lente: </span>
                            {glassesToOrder.lensType}
                        </h5>
                    ) : ""}
                    {glassesToOrder.recommendationsForUse ? (
                        <h5 className="text-muted mb-4">
                            <span className="fw-bold">Recomendaciones de uso: </span>
                            {glassesToOrder.recommendationsForUse}
                        </h5>
                    ) : ""}
                </div>
            );
        } else return "";
    };

    const renderScleralLenses = (scleralLenses: ScleralLenses) => {
        if (scleralLenses) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold my-3">
                        Lentes esclerales
                    </h4>
                    { scleralLenses.definitive === 1 ? 
                        <h5 className="text-muted fw-bold">
                                    Definitiva: SÍ - 
                                    Prueba: NO
                        </h5>:
                        <h5 className="text-muted fw-bold">
                                    Definitiva: NO - 
                                    Prueba: SÍ
                        </h5> 
                    }
                    <div className="row">
                        {scleralLenses.od ? (
                            <div className="col-6">
                                <h5 className="text-muted fw-bold">
                                    Lente OD
                                </h5>
                                {scleralLenses.od.design ? (
                                    <h5 className="text-muted">
                                        <span className="fw-bold">Diseño: </span>
                                        {scleralLenses.od.design}
                                    </h5>
                                ) : ""}
                                {scleralLenses.od.power ? (
                                    <h5 className="text-muted">
                                        <span className="fw-bold">Power: </span>{" "}
                                        {scleralLenses.od.power}
                                    </h5>
                                ) : ""}
                                {scleralLenses.od.sag ? (
                                    <h5 className="text-muted">
                                        <span className="fw-bold">SAG: </span>{" "}
                                        {scleralLenses.od.sag}
                                    </h5>
                                ) : ""}
                                {scleralLenses.od.diameter ? (
                                    <h5 className="text-muted">
                                        <span className="fw-bold">Diámetro: </span>{" "}
                                        {scleralLenses.od.diameter}
                                    </h5>
                                ) : ""}
                                {scleralLenses.od.visionShoot ? (
                                    <h5 className="text-muted">
                                        <span className="fw-bold">AV: </span>{" "}
                                        {scleralLenses.od.visionShoot}
                                    </h5>
                                ) : ""}
                                {scleralLenses.od.clearance ? (
                                    <h5 className="text-muted">
                                        <span className="fw-bold">Clearance: </span>{" "}
                                        {scleralLenses.od.clearance}
                                    </h5>
                                ) : ""}
                                {scleralLenses.od.others ? (
                                    <h5 className="text-muted">
                                        <span className="fw-bold">Otros: </span>{" "}
                                        {scleralLenses.od.others}
                                    </h5>
                                ) : ""}
                            </div>
                        ) : ""}

                        {scleralLenses.oi ? (
                            <div className="col-6">
                                <h5 className="text-muted fw-bold">
                                    Lente OI
                                </h5>
                                {scleralLenses.oi.design ? (
                                    <h5 className="text-muted">
                                        <span className="fw-bold">Diseño: </span>
                                        {scleralLenses.oi.design}
                                    </h5>
                                ) : ""}
                                {scleralLenses.oi.power ? (
                                    <h5 className="text-muted">
                                        <span className="fw-bold">Power: </span>{" "}
                                        {scleralLenses.oi.power}
                                    </h5>
                                ) : ""}
                                {scleralLenses.oi.sag ? (
                                    <h5 className="text-muted">
                                        <span className="fw-bold">SAG: </span>{" "}
                                        {scleralLenses.oi.sag}
                                    </h5>
                                ) : ""}
                                {scleralLenses.oi.diameter ? (
                                    <h5 className="text-muted">
                                        <span className="fw-bold">Diámetro: </span>{" "}
                                        {scleralLenses.oi.diameter}
                                    </h5>
                                ) : ""}
                                {scleralLenses.oi.visionShoot ? (
                                    <h5 className="text-muted">
                                        <span className="fw-bold">AV: </span>{" "}
                                        {scleralLenses.oi.visionShoot}
                                    </h5>
                                ) : ""}
                                {scleralLenses.oi.clearance ? (
                                    <h5 className="text-muted">
                                        <span className="fw-bold">Clearance: </span>{" "}
                                        {scleralLenses.oi.clearance}
                                    </h5>
                                ) : ""}
                                {scleralLenses.oi.others ? (
                                    <h5 className="text-muted">
                                        <span className="fw-bold">Otros: </span>{" "}
                                        {scleralLenses.oi.others}
                                    </h5>
                                ) : ""}
                            </div>
                        ) : ""}
                    </div>
                </div>
            );
        } else return "";
    };

    const renderCentralClearence = (centralClearance: ClearenceCentral[] | null) => {
        if (centralClearance) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Clearance central
                    </h4>
                    <CentralClearance
                        isHistory
                        adaptationPayload={{ clearenceCentral: Object.values(centralClearance) }}
                        readOnly
                    />
                </div>
            );
        } else return "";
    };

    const renderExtDiagnostic = (extDiagnostic: Cie10EXTDiagnostic | null) => {
        if (extDiagnostic && Object.values(extDiagnostic).length) {
            const mainExt = Object.values(extDiagnostic).find(item => item.isMain === 1);

            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Impresión diagnóstica
                    </h4>
                    <table className="table table-bordered">
                        <thead>
                            <tr className="text-secondary">
                                <th>Fecha</th>
                                <th className="text-center">CIE-10</th>
                                <th>Impresión diagnóstica</th>
                                <th className="text-center">Ojo</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.values(extDiagnostic).map((item) => (
                                <tr key={`${item.id}-${item.clhId}`} style={{ backgroundColor: item.isMain ? "#fcfae6" : undefined }}>
                                    <td className="align-middle">{item.date}</td>
                                    <td align="center" className="align-middle">{item.cie10Code}</td>
                                    <td>{item.cie10Description}</td>
                                    <td className="align-middle" align="center">{item.dbo?.name}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {mainExt && mainExt.evolution ? (
                        <h5>
                            <span className="fw-bold">Tiempo de evolución: </span>
                            {mainExt.evolution} {mainExt.evolutionTime?.name}
                        </h5>
                    ) : ""}
                </div>
            );
        } else {
            return "";
        }
    };

    const renderAnalysisAndPlan = (analysisPlan: string | null) => {
        if (analysisPlan) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Análisis y plan
                    </h4>
                    <h5>{analysisPlan}</h5>
                </div>
            );
        } else {
            return "";
        }
    };

    const render = () => {
        const attentionOrigin = adaptationDetail.generalsFields ? adaptationDetail.generalsFields[0] : null;
        const contactLenses = formatHistoryDetails(adaptationDetail.contactLenses);
        const overfraction = formatHistoryDetails(adaptationDetail.overfraction);
        const adaptation = formatHistoryDetails(adaptationDetail.adaptation);
        const glassesToOrder = formatHistoryDetails(adaptationDetail.glassesToOrder);
        const scleralLenses = formatHistoryDetails(adaptationDetail.scleralLenses);
        const centralClearance = formatHistoryDetails(adaptationDetail.clearenceCentral);
        const extDiagnostic = formatHistoryDetails(adaptationDetail.cie10ExtDiagnostic);
        const analysisPlan = formatHistoryDetails(adaptationDetail?.generalsFields ? adaptationDetail?.generalsFields[0]?.analysisPlanJst : "");

        return (
            <div className="detail-container">
                <div className={`${!forDownload ? "columns" : "column"}`}>
                    {renderAttentionOrigin(attentionOrigin)}
                    {renderContactLenses(contactLenses)}
                    {renderOverfraction(overfraction)}
                    {renderAdaptation(adaptation)}
                    {renderGlassesToOrder(glassesToOrder)}
                    {renderScleralLenses(scleralLenses)}
                    <div>{renderCentralClearence(centralClearance)}</div>
                    {/* <div className="item">
                        <h4 className="text-primary fw-bold mb-3">
                            Clearance central
                        </h4>
                        <div className="d-flex justify-content-between">
                            <div className="d-flex flex-column align-items-center">
                                <h5 className="text-muted fw-bold">OD</h5>
                                <div className="me-3 overflow-hidden eye">
                                    <div className="containerElement">
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-column align-items-center">
                                <h5 className="text-muted fw-bold">OI</h5>
                                <div className="me-3 overflow-hidden eye">
                                    <div className="containerElement">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h5 className="text-muted fw-bold mb-3">Observaciones OD: </h5>
                        <ol>
                            <li>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aperiam, nam?</li>
                            <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta, alias cumque.</li>
                            <li>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ducimus laborum quam doloribus?</li>
                        </ol>

                        <h5 className="text-muted fw-bold">Observaciones OI: </h5>
                        <ol>
                            <li>Lorem ipsum dolor sit, amet consectetur adipisicing elit. At, ex consequatur.</li>
                            <li>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Tempore a quam sunt!</li>
                            <li>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aperiam, nam?</li>
                        </ol>
                    </div> */}
                    <div className="block-item">
                        {renderExtDiagnostic(extDiagnostic)}
                        {renderAnalysisAndPlan(analysisPlan)}
                    </div>
                </div>
            </div>
        );
    };

    return render();
}