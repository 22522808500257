import { useAppDispatch, useAppSelector } from "@/hooks";
import { useEffect, useState } from "react";
import { deleteConcepts, getMedicalConcepts, getOrgans, getTranscriptionForm, getTranscriptionReport, updateTranscription, validateTranscription } from "./Transcription.actions";
import { BodyVariable, MedicalConceptsBody, OrgansList, ResultsTranscriptionForm, ResultsTranscriptionValidation, TranscriptionPayload, TranscriptionReport, Variable, medicalConcepts } from "@/models/transcription/transcription";
import { Images } from "@/models/sheets/diagnosticAids";
import { Button, Modal, SectionCard, Select } from "@/components";
import { useDebounce, useDebouncedCallback } from "use-debounce";
import AudioPlayerComponent from "@/components/AudioRecorder/AudioPlayer/AudioPlayer";
import { IconCirclePlus, IconReportSearch, IconTable, IconTrash } from "@tabler/icons-react";
import { useNavigate } from "react-router";
import routes from "@/constants/routes";
import { SelectOption } from "@/components/Select/select";
import { fireWarningAlert, formatteDocument } from "@/utils";
import ConceptsModal from "./ConceptsModal";
import TextConfigModal from "../PatientAttention/Consultation/AttentionOrigin/TextConfig/TextConfig";

interface DetailParams {
    sheet?: string;
    mcfId?: number;
    adxId?: number;
}

const TranscriptionForm = ({ data, setImagesPayload, uploadFilesFunction, setFileSelected }:
    {
        data: TranscriptionPayload,
        setImagesPayload: (data: Images) => void;
        uploadFilesFunction: (section: "report" | "od" | "oi", setting: boolean) => void;
        setFileSelected: (data: {
            url: string,
            value: string | number;
        }) => void;
    }) => {
    const accountId = useAppSelector(state => state.workspace.id);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const userId = useAppSelector(state => state.auth.user_data.id);
    const [trigger, setTrigger] = useState(0);
    const [triggerConcepts, setTriggerConcepts,] = useState(0);
    const [triggerGet, setTriggerGet] = useState(0);
    const [bodyValidationsRender, setBodyValidationsRender] = useState<ResultsTranscriptionValidation>({});
    const [variables, setVariables] = useState<BodyVariable[]>([]);
    const [findingsVars, setFindingsVars] = useState<Variable[]>([]);
    const [dataSheet, setDataSheet] = useState<ResultsTranscriptionForm>({});
    const [medicalConcept, setMedicalConcept] = useState<medicalConcepts[]>([]);
    const [organList, setOrganList] = useState<OrgansList[]>([]);
    const [searchValue, setSearchValue] = useDebounce<string>("", 1000);

    const [medicalConceptData, setMedicalConceptData] = useState<MedicalConceptsBody[]>([{
        id: "",
        show: true,
        organId: null,

    }]);
    const [conclusionModal, setConclusionModal] = useState<{ isOpen: boolean; current: string | null; }>({
        isOpen: false,
        current: "conclusion"
    });

    const [conceptsModalState, setConceptsModalState] = useState<{ isOpen: boolean; current?: string | null; }>({
        isOpen: false,
    });
    const [reportInfo, setReportInfo] = useState<TranscriptionReport>({
        "body": {
            "datetime_it_was_made": "",
            "event_number": "",
            "full_name_of_the_institution": "",
            "patient_age": undefined,
            "schedule_name": ""
        },
        "footer": {
            "address": "",
            "appointment_email": "",
            "appointment_phone": "",
            "doctor": {
                "medical_record": "",
                "name": "",
                "position": "",
                "signature": ""
            }
        },
        "header": {
            "logo": "",
            "name_of_the_institution": "",
            "patient": {
                "birthdate": "",
                "doc": "",
                "gender": "",
                "name": "",
                "patient_age": undefined
            }
        }
    });
    const [detailModalConfig, setDetailModalConfig] = useState<{ isOpen: boolean; data: DetailParams; }>({
        isOpen: false,
        data: {
            sheet: "",
            mcfId: undefined,
            adxId: undefined,
        }
    });

    const handleOpenNoInsTextModal = (name: string): void => setConclusionModal({
        current: name, isOpen: true
    });
    const handleOpenConceptModal = (name: string): void => setConceptsModalState({
        current: name, isOpen: true
    });
    const handleCloseNoInsTextModal = (): void => setConclusionModal({
        current: "conclusion", isOpen: false
    });
    const handleCloseConceptModal = (): void => setConceptsModalState({
        isOpen: false
    });
    const handleChangeText = (text: string) => {
        const options = {
            ...dataSheet,
            conclusion: text
        };
        setDataSheet(options);
        onSubmitSection("conclusion", text);
    };

    const handleChangeStrategy = (text: string) => {
        const options = { ...dataSheet, strategy: text };
        setDataSheet(options);
        onSubmitSection("strategy", text);
    };

    const handleChangeReferredBy = (text: string) => {
        const options = { ...dataSheet, referredBy: text };
        setDataSheet(options);
        onSubmitSection("referredBy", text);
    };

    const handleAttatchText = (text: string) => {
        const options = {
            ...dataSheet,
            conclusion: dataSheet?.conclusion?.length
                ? `${dataSheet.conclusion}\n${text}`
                : text
        };
        setDataSheet(options);
        onSubmitSection("conclusion", dataSheet?.conclusion?.length
            ? `${dataSheet.conclusion}\n${text}`
            : text);
        handleCloseNoInsTextModal();
    };

    const handlerTranscription = async (session: string) => {
        const resultsValidation = await dispatch(validateTranscription({
            srvId: data?.srvId,
            adxId: data?.adxId,
            transcriberBy: userId,
            session: session,
        }));
        if (session === "end" && resultsValidation.success) {
            navigate(routes.workspaceTranscription);
        }

        return resultsValidation;
    };

    useEffect(() => {
        async function fetchData() {
            const resultsValidation = await handlerTranscription("start");
            if (resultsValidation.results) {
                setBodyValidationsRender(resultsValidation.results);
                if (resultsValidation.results.variables) {
                    setVariables(resultsValidation.results.variables);
                }
            }

            const results = await dispatch(getTranscriptionForm({
                srvId: data?.srvId,
                adxId: data?.adxId,
            }));
            if (results) {
                setDataSheet(results);
                if (results.medicalConcepts && results.medicalConcepts.length > 0) {
                    const temp = results.medicalConcepts;
                    temp[0].show = true;
                    setMedicalConceptData(temp);
                }
                if (results.variables) {
                    setFindingsVars(results.variables);
                }
                if (results.images) {
                    setImagesPayload(results.images);
                }
                if (results?.images?.od && results?.images?.od?.length > 0) {
                    uploadFilesFunction("od", true);
                    setFileSelected({ value: (results?.images?.od[0].id ?? results?.images?.od[0].value) ?? "", url: (results?.images?.od[0].url ?? `data:application/pdf;base64,${results?.images?.od[0].base64}`) ?? "" });
                } else if (results?.images?.oi && results?.images?.oi?.length > 0) {
                    uploadFilesFunction("oi", true);
                    setFileSelected({ value: (results?.images?.oi[0].id ?? results?.images?.oi[0].value) ?? "", url: (results?.images?.oi[0].url ?? `data:application/pdf;base64,${results?.images?.oi[0].base64}`) ?? "" });
                }
            }
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        async function fetchData() {
            const results = await dispatch(getTranscriptionForm({
                srvId: data?.srvId,
                adxId: data?.adxId,
            }));
            if (results) {
                setDataSheet(results);
                if (results.medicalConcepts && results.medicalConcepts.length > 0) {
                    const temp = results.medicalConcepts;
                    temp[0].show = true;
                    setMedicalConceptData(temp);
                }
                if (results.variables) {
                    setFindingsVars(results.variables);
                }
                if (results.images) {
                    setImagesPayload(results.images);
                }
                if (results?.images?.od && results?.images?.od?.length > 0) {
                    uploadFilesFunction("od", true);
                    setFileSelected({ value: (results?.images?.od[0].id ?? results?.images?.od[0].value) ?? "", url: (results?.images?.od[0].url ?? `data:application/pdf;base64,${results?.images?.od[0].base64}`) ?? "" });
                } else if (results?.images?.oi && results?.images?.oi?.length > 0) {
                    uploadFilesFunction("oi", true);
                    setFileSelected({ value: (results?.images?.oi[0].id ?? results?.images?.oi[0].value) ?? "", url: (results?.images?.oi[0].url ?? `data:application/pdf;base64,${results?.images?.oi[0].base64}`) ?? "" });
                }
            }
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setTriggerGet]);

    useEffect(() => {
        async function fetchData() {
            if (bodyValidationsRender.medicalConcept?.enabled) {
                const medicalConcept = await dispatch(getMedicalConcepts(accountId, searchValue, 1, data?.srvId));
                setMedicalConcept(medicalConcept);

            }
        }
        fetchData();
    }, [bodyValidationsRender.medicalConcept, accountId, searchValue, triggerConcepts, data?.srvId, dispatch]);

    useEffect(() => {
        async function fetchData() {
            if (bodyValidationsRender.medicalConcept?.enabled) {
                const organsList = await dispatch(getOrgans(accountId));
                setOrganList(organsList);

            }
        }
        fetchData();
    }, [bodyValidationsRender.medicalConcept, accountId, dispatch]);

    const onSubmitSection = useDebouncedCallback((section, payload) => {
        dispatch(updateTranscription({
            adxId: dataSheet.adxId,
            id: dataSheet.id,
            [section]: payload
        }));
    }, 1000);

    const onDeleteSection = useDebouncedCallback((section, payload) => {
        dispatch(deleteConcepts({
            adxId: dataSheet.adxId,
            id: dataSheet.id,
            [section]: payload
        }));
    }, 0);

    const renderStrategy = () => {
        return (
            <SectionCard className="my-4">
                <div className="d-flex align-items-center justify-content-between">
                    <h5 className="fw-bold text-secondary">Estrategia</h5>
                </div>
                <hr className="lineCards" />
                <input
                    className='form-control'
                    placeholder='Escribir...'
                    value={dataSheet.strategy}
                    onChange={({ target }) => handleChangeStrategy(target.value)}
                />
            </SectionCard>
        );
    };

    const renderReferredBy = () => {
        return (
            <SectionCard className="my-4">
                <div className="d-flex align-items-center justify-content-between">
                    <h5 className="fw-bold text-secondary">Referido por</h5>
                </div>
                <hr className="lineCards" />
                <input
                    className='form-control'
                    placeholder='Escribir...'
                    value={dataSheet.referredBy}
                    onChange={({ target }) => handleChangeReferredBy(target.value)}
                />
            </SectionCard>
        );
    };

    const renderFindings = () => {
        const onChangeVariables = (laterality: "oi" | "od" | null, value: string, field: string, variable: BodyVariable) => {
            const variableList = findingsVars && findingsVars?.length > 0 ? findingsVars : [];
            if (variableList.some(vari => vari.varId === variable.id)) {
                const tempVariable = variableList.find(vari => vari.varId === variable.id);
                if ((tempVariable && tempVariable?.fields?.length > 0) && tempVariable.fields.some(field => field.laterality === laterality)) {
                    const tempField = tempVariable.fields.find(field => field.laterality === laterality);
                    if (tempField) {
                        tempField.observations = value;
                        setFindingsVars(variableList);
                        onSubmitSection("variables", variableList);
                    }
                }
            }
        };
        const fieldValueInput = (laterality: "oi" | "od" | null, variable: BodyVariable) => {
            const variableFind = findingsVars && findingsVars.find(vari => vari.varId === variable.id);
            const field = (variableFind?.fields.find(field => field.laterality === laterality))?.value;
            return field;
        };
        const fieldObservationInput = (laterality: "oi" | "od" | null, variable: BodyVariable) => {
            const variableFind = findingsVars && findingsVars.find(vari => vari.varId === variable.id);
            const field = (variableFind?.fields.find(field => field.laterality === laterality))?.observations;
            return field;
        };

        const showLaterality = variables && variables?.length > 0 && variables.some((variable) =>
            variable.od || variable.oi
        );

        return (
            <SectionCard className="my-4" cardAidStyle >
                <div className="d-flex align-items-center justify-content-between">
                    <h5 className="fw-bold text-secondary">Hallazgos</h5>
                </div>
                <hr className="lineCards" />

                <div className="row">
                    {variables && variables?.length > 0 &&
                        <div className="col d-grid gap-2">
                            <div className="row">
                                <div className="col-4">
                                    <span className="text-secondary fw-bold ">Variables</span>
                                </div>
                                {showLaterality &&
                                    <>
                                        <div className="col-4 text-center">
                                            <span className="text-secondary fw-bold ">OI</span>
                                        </div>
                                        <div className="col-4 text-center">
                                            <span className="text-secondary fw-bold text-center">OD</span>
                                        </div>
                                    </>
                                }
                            </div>
                            {variables?.length > 0 && variables?.map((variable) => {
                                return (
                                    <div className="row" key={variable.id}>
                                        <div className="col-4 align-self-center">
                                            <label className="text-label ">{variable.name} ({variable.unit ?? ""})</label>
                                        </div>
                                        <div className="col row">

                                            {(variable.oi || variable.od) ?
                                                <>
                                                    <div className="col-2">
                                                        <input
                                                            disabled
                                                            type="text"
                                                            className="form-control form-control-sm "
                                                            value={fieldValueInput("oi", variable)}
                                                        />
                                                    </div>
                                                    <div className="col-4">
                                                        <input
                                                            disabled={!variable.oi || dataSheet.status === "generated_report"}
                                                            type="text"
                                                            className="form-control form-control-sm "
                                                            defaultValue={fieldObservationInput("oi", variable)}
                                                            onChange={({ target }) => {
                                                                onChangeVariables("oi", target.value, "variables", variable);
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-2">
                                                        <input
                                                            disabled
                                                            type="text"
                                                            className="form-control form-control-sm "
                                                            value={fieldValueInput("od", variable)}
                                                        />
                                                    </div>
                                                    <div className="col-4">
                                                        <input
                                                            disabled={!variable.od || dataSheet.status === "generated_report"}
                                                            type="text"
                                                            className="form-control form-control-sm "
                                                            defaultValue={fieldObservationInput("od", variable)}
                                                            onChange={({ target }) => {
                                                                onChangeVariables("od", target.value, "variables", variable);
                                                            }}
                                                        />
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div className="col-2">
                                                        <input
                                                            disabled
                                                            type="text"
                                                            className="form-control form-control-sm "
                                                            value={fieldValueInput(null, variable)}
                                                        />
                                                    </div>
                                                    <div className="col-10">
                                                        <input
                                                            disabled={dataSheet.status === "generated_report"}
                                                            type="text"
                                                            className="form-control form-control-sm "
                                                            defaultValue={fieldObservationInput(null, variable)}
                                                            onChange={({ target }) => {
                                                                onChangeVariables(null, target.value, "variables", variable);
                                                            }}
                                                        />
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                );
                            })}
                            <div className="py-2">
                                <textarea
                                    name="comment"
                                    className="form-control no-resize w-100"
                                    onChange={({ target }) => {
                                        setDataSheet({ ...dataSheet, findingsObservations: target.value });
                                        onSubmitSection("findingsObservations", target.value);
                                    }}
                                    value={dataSheet?.findingsObservations}
                                    style={{ height: "100%" }}
                                    disabled={dataSheet.status === "generated_report"}
                                />
                            </div>
                        </div>
                    }
                </div>

            </SectionCard>
        );
    };
    const renderMedicalConcepts = () => {

        const medicalConceptList = medicalConcept && medicalConcept.map(concept => ({
            label: concept.name,
            value: concept.id,
            color: concept.risk.color
        }));
        const organsListOptions = organList && organList.map(organ => ({
            label: organ.name,
            value: organ.id,
        }));

        const rowConcept = (row: MedicalConceptsBody, index: number) => {

            const deleteConcept = (id: number | null) => {
                const tempData = medicalConceptData.filter((concept, ind) => ind !== id);
                const item = medicalConceptData.filter((concept, ind) => ind === id);
                setMedicalConceptData(tempData);
                onDeleteSection("medicalConcepts", item);
            };

            const onChangeConcept = (variable: "id" | "organId", option: SelectOption, row: MedicalConceptsBody) => {
                const tempData = [...medicalConceptData];
                if (variable === "id") {
                    const isRepeted = tempData.some(concept => concept.id === option.value);
                    if (!isRepeted) {
                        const concept = tempData.find(concept => concept.id === row.id);
                        if (concept) {
                            concept[variable] = option.value;
                            if (concept.id && concept.organId) {
                                onSubmitSection("medicalConcepts", [concept]);
                                setTriggerGet(triggerGet + 1);
                            }
                        }
                        setMedicalConceptData(tempData);
                    } else {
                        fireWarningAlert("El concepto seleccionado ya se encuentra agregado a la transcripción", "Intenta de nuevo");
                        setTrigger(trigger + 1);
                    }
                } else {
                    const concept = tempData.find(concept => concept.id === row.id);
                    if (concept) {
                        concept[variable] = option.value;
                        if (concept.id && concept.organId) {
                            onSubmitSection("medicalConcepts", [concept]);
                            setTriggerGet(triggerGet + 1);
                        }
                    }
                    setMedicalConceptData(tempData);
                }
            };

            const selectedRow = medicalConceptData.find((item) => item.id === row.id);

            return (<div className={"row pb-2"} key={row.id}>
                <div className="col-9">
                    <Select
                        variant="normal"
                        placeholder="Seleccionar..."
                        options={medicalConceptList}
                        emptyOptionsLabel="No hay artículos disponibles"
                        width="100%"
                        optionColor
                        key={trigger}
                        value={medicalConceptList.find(concept => concept.value === selectedRow?.id) ?? { label: "", value: "" }}
                        onChange={({ option }) => onChangeConcept("id", option, row)}
                        disabled={dataSheet.status === "generated_report"}
                    />
                </div>
                <div className="col-2">
                    <Select
                        variant="normal"
                        style={{ width: "100%", maxWidth: 80 }}
                        placeholder="Seleccionar..."
                        options={organsListOptions}
                        emptyOptionsLabel="No hay artículos disponibles"
                        value={organsListOptions.find(concept => concept.value === selectedRow?.organId)}
                        onChange={({ option }) => onChangeConcept("organId", option, row)}
                        disabled={dataSheet.status === "generated_report"}
                    />
                </div>
                {(!row.show && dataSheet.status !== "generated_report") &&
                    <div className="col-1 align-self-center">
                        <div className="pointer text-muted "
                            onClick={() => deleteConcept(index ?? null)}
                        >
                            <IconTrash size={18} />
                        </div>
                    </div>
                }
            </div>);
        };

        const addConceptRow = () => {
            if (medicalConceptData[medicalConceptData.length - 1].id !== "" &&
                medicalConceptData[medicalConceptData.length - 1].organId !== null) {

                setMedicalConceptData([
                    ...medicalConceptData,
                    {
                        id: "",
                        organId: null
                    }
                ]);
            } else {
                fireWarningAlert("Por favor llenar todos los campos", "Intenta de nuevo");
            }
        };


        return (
            <SectionCard className="my-4" cardAidStyle >
                <div className="d-flex align-items-center justify-content-between">
                    <h5 className="fw-bold text-secondary">Concepto médico</h5>
                    {dataSheet.status !== "generated_report" &&
                        <span className="pointer text-muted"
                            onClick={() => handleOpenConceptModal("analysisAndPlan")}
                        >Tabla maestra <IconTable size={16} /> </span>
                    }
                </div>
                <hr className="lineCards" />
                <div className="py-2">
                    <div className="row">
                        <div className="col-9 fw-bold text-secondary"> Concepto</div>
                        <div className="col-2 fw-bold text-secondary"> Órgano</div>
                        <div className="col-1">&nbsp;</div>
                    </div>

                    {
                        medicalConceptData.length > 0 ? medicalConceptData.map((row, index) => {
                            return rowConcept(row, index);
                        }) :
                            <></>
                    }
                    <hr className="lineCards mt-4 mb-0" />
                    <div className="d-flex justify-content-end">
                        <Button
                            variant="text"
                            className="align-self-end mt-3"
                            startIcon={<IconCirclePlus />}
                            onClick={addConceptRow}
                            disabled={dataSheet.status === "generated_report"}
                        >
                            Añadir
                        </Button>
                    </div>

                </div>
                <ConceptsModal
                    isOpen={conceptsModalState.isOpen}
                    current={conceptsModalState.current as string}
                    onClose={handleCloseConceptModal}
                    width={800}
                    dataArray={medicalConcept}
                    setSearchValue={setSearchValue}
                    // onSelectItem={(_, item) => handleAttatchText(item.label)}
                    setTriggerConcepts={setTriggerConcepts}
                    triggerConcepts={triggerConcepts}
                    srvId={data?.srvId}
                />
            </SectionCard>
        );
    };
    const renderConclusion = () => {

        return (
            <SectionCard className="my-4" cardAidStyle >
                <div className="d-flex align-items-center justify-content-between">
                    <h5 className="fw-bold text-secondary">Conclusión</h5>
                </div>
                <hr className="lineCards" />
                <div className="py-2">
                    <textarea
                        className="form-control no-resize w-100"
                        style={{ height: 100 }}
                        disabled={dataSheet.status === "generated_report"}
                        onContextMenu={(event) => {
                            event.preventDefault();
                            dataSheet.status !== "generated_report" && handleOpenNoInsTextModal("conclusion");
                        }}
                        onChange={({ target }) => handleChangeText(target.value)}
                        value={dataSheet.conclusion}
                    />
                </div>
                <TextConfigModal
                    title="Textos predefinidos no institucionales"
                    isOpen={conclusionModal.isOpen}
                    current={conclusionModal.current as string}
                    onClose={handleCloseNoInsTextModal}
                    width={800}
                    positionModal="center"
                    onSelectItem={(_, item) => handleAttatchText(item.label)}
                />

            </SectionCard>
        );
    };


    const onCloseDetails = () => {
        setDetailModalConfig({
            data: {
                mcfId: undefined,
                sheet: ""
            },
            isOpen: false
        });
    };

    const onOpenDetails = async () => {
        const results = await dispatch(getTranscriptionReport({
            eaccount: accountId,
            adxId: data?.adxId,
        }));

        if (results?.header) {
            setReportInfo(results);
        }

        const response = await dispatch(getTranscriptionForm({
            srvId: data?.srvId,
            adxId: data?.adxId,
        }));
        if (response) {
            setDataSheet(response);
            if (response.medicalConcepts && response.medicalConcepts.length > 0) {
                const temp = response.medicalConcepts;
                temp[0].show = true;
                setMedicalConceptData(temp);
            }
            if (response.variables) {
                setFindingsVars(response.variables);
            }
            if (response.images) {
                setImagesPayload(response.images);
            }
            if (response?.images?.od && response?.images?.od?.length > 0) {
                uploadFilesFunction("od", true);
                setFileSelected({ value: (response?.images?.od[0].id ?? response?.images?.od[0].value) ?? "", url: (response?.images?.od[0].url ?? `data:application/pdf;base64,${response?.images?.od[0].base64}`) ?? "" });
            } else if (response?.images?.oi && response?.images?.oi?.length > 0) {
                uploadFilesFunction("oi", true);
                setFileSelected({ value: (response?.images?.oi[0].id ?? response?.images?.oi[0].value) ?? "", url: (response?.images?.oi[0].url ?? `data:application/pdf;base64,${response?.images?.oi[0].base64}`) ?? "" });
            }
        }

        setDetailModalConfig({
            isOpen: true,
            data: {
                mcfId: undefined,
                sheet: "transcription",
                adxId: data?.adxId,
            }
        });
    };

    const renderReport = () => {
        return (
            <Modal className="px-5 py-4 pt-5 align-items-center" width={680} onClose={onCloseDetails} isOpen={detailModalConfig.isOpen}>

                <div className="renderReport p-2 mt-2 pt-1"
                    style={{ height: "calc( 98vh - 100px)", overflow: "auto", width: "100%" }}
                >
                    <section className="bg-white  h-100"
                        style={{ padding: "1rem" }}
                    >
                        <div className="h-100 d-flex flex-column justify-content-between">
                            <div>
                                <div className="d-flex pb-2">
                                    <div className="text-secondary text-start col-4"><img src={reportInfo.header.logo} height={50} width={100} alt="firm doc" /></div>
                                    <div className="text-secondary text-center col-4 align-self-center"> {reportInfo.header.name_of_the_institution}</div>
                                </div>
                                <div className="d-flex  py-2 align-items-center bg-light text-muted mt-3">
                                    <div className="col-6">
                                        <div className="d-flex mx-3 align-items-center">
                                            <div className="col px-0">
                                                <h6 className="fw-bold">Paciente:</h6>
                                                <h6 className="fw-bold">Documento:</h6>
                                            </div>
                                            <div className="col-8 px-0">
                                                <h6>{reportInfo.header?.patient?.name}</h6>
                                                <h6>{formatteDocument({ value: reportInfo.header?.patient?.doc }).format}</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="d-flex align-items-center">
                                            <div className="col-6">
                                                <h6 className="fw-bold nowrap">Fecha de nacimiento:</h6>
                                                <h6 className="fw-bold">Género:</h6>
                                            </div>
                                            <div className="col-6">
                                                <h6>{reportInfo.header?.patient?.birthdate}</h6>
                                                <h6>{reportInfo.header?.patient?.gender}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className=" text-primary  px-2 mx-0 mb-2 mt-4 text-center justify-content-center">
                                    {bodyValidationsRender?.title ?? "Título"}
                                </div>

                                <div className="mt-3">
                                    {bodyValidationsRender?.introduction?.enabled && bodyValidationsRender?.introduction?.showTitle &&
                                        <div className="mx-0 text-primary"> Introducción </div>
                                    }
                                    {
                                        bodyValidationsRender?.introduction?.enabled &&
                                        <div className="col-12 mx-0">
                                            <div dangerouslySetInnerHTML={{ __html: dataSheet?.introduction ?? "texto de introducción" }} />
                                        </div>
                                    }
                                </div>
                                {
                                    (bodyValidationsRender?.strategy?.enabled || true) &&
                                    <div className="col-12 mx-0">
                                        <div className="fw-bold text-center" dangerouslySetInnerHTML={{ __html: dataSheet?.strategy ?? "" }} />
                                    </div>
                                }
                                {
                                    (bodyValidationsRender?.referredBy?.enabled || true) &&
                                    <div className="col-12 mx-0">
                                        {bodyValidationsRender?.referredBy?.showTitle &&  (
                                            <div className="text-primary">Referido por</div>
                                        )}
                                        <div dangerouslySetInnerHTML={{ __html: dataSheet?.referredBy ?? "" }} />
                                    </div>
                                }

                                {bodyValidationsRender?.images && (
                                    <div className="mt-3 text-center">
                                        {bodyValidationsRender.images.map(image => (
                                            <img key={image.id} width="75%" className="img-fluid" src={image.url} alt={image.filename} />
                                        ))}
                                    </div>
                                )}

                                {bodyValidationsRender.findings?.enabled &&
                                    <>
                                        <div className="mt-3">
                                            {bodyValidationsRender?.findings?.enabled && bodyValidationsRender?.findings?.showTitle &&
                                                <div className=" text-primary mx-0"> Hallazgos </div>
                                            }
                                            {bodyValidationsRender?.findings?.enabled && (
                                                <div>
                                                    <div className="mx-0 d-flex flex-column">
                                                        {bodyValidationsRender?.variables && bodyValidationsRender?.variables?.length > 0 && (
                                                            bodyValidationsRender?.variables?.map((variable) => {
                                                                const field = (laterality: "oi" | "od" | null, variable: BodyVariable) => {
                                                                    const variableFind = findingsVars && findingsVars.find(vari => vari.varId === variable.id);
                                                                    const field = (variableFind?.fields.find(field => field.laterality === laterality));
                                                                    return field;
                                                                };
                                                                return (
                                                                    <div key={variable.id}>
                                                                        {!!variable.od &&
                                                                            <>
                                                                                <div className="row">
                                                                                    <span className=""> <b> {variable.name}&nbsp;OD:</b>
                                                                                        &nbsp;{field("od", variable)?.value} {variable.unit} -
                                                                                        {field("od", variable)?.observations}</span> </div>
                                                                            </>
                                                                        }
                                                                        {!!variable.oi && <>
                                                                            <div className="row">
                                                                                <span className="">
                                                                                    <b> {variable.name}&nbsp;OI:</b>
                                                                                    &nbsp;{field("oi", variable)?.value} {variable.unit} -
                                                                                    {field("oi", variable)?.observations}</span>

                                                                            </div>
                                                                        </>
                                                                        }
                                                                        {!variable.od && !variable.oi &&
                                                                            <div className="row">
                                                                                <span className="">
                                                                                    <b> {variable.name}&nbsp;:</b>
                                                                                    &nbsp;{field(null, variable)?.value} {variable.unit} -
                                                                                    {field(null, variable)?.observations}</span>
                                                                            </div>}
                                                                        <br />
                                                                    </div>
                                                                );
                                                            })
                                                        )}
                                                    </div>

                                                    <div>{dataSheet?.findingsObservations && dataSheet?.findingsObservations}</div>
                                                </div>
                                            )}
                                        </div>
                                    </>}
                                <div className="mt-3">
                                    {bodyValidationsRender?.medicalConcept?.enabled && bodyValidationsRender?.medicalConcept?.showTitle &&
                                        <div className=" text-primary mx-0"> Concepto médico </div>
                                    }
                                    {bodyValidationsRender?.medicalConcept?.enabled &&
                                        <div className="d-flex flex-column mx-0">
                                            <div className="d-flex flex-column justify-content-between">
                                                <div className="d-flex justify-content-between mt-3">
                                                    <div className="col-6 text-primary text-start"> Concepto</div>
                                                    <div className="col-6 text-primary text-end"> Órgano</div>
                                                </div>
                                                <hr className="mb-2 mt-0" />

                                                {medicalConceptData?.length > 0 && medicalConceptData.map(concept => (
                                                    <div
                                                        key={concept.id}
                                                        className="border-bottom d-flex py-1 justify-content-between"
                                                    >
                                                        <div style={{ color: concept?.risk?.color, whiteSpace: "pre-line" }} className="col-10 text-start">{concept?.name}</div>
                                                        <div className="col-2 text-end">{concept?.organ?.name}</div>
                                                    </div>
                                                ))}

                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="mt-3">
                                    {bodyValidationsRender?.conclusion?.enabled && bodyValidationsRender?.conclusion?.showTitle &&
                                        <div className="text-primary mx-0"> Conclusión </div>
                                    }
                                    {
                                        bodyValidationsRender?.conclusion?.enabled &&
                                        <div className={"mx-0"} style={{ whiteSpace: "pre-line" }}> {dataSheet?.conclusion} </div>
                                    }
                                </div>
                            </div>
                            <div className="d-flex flex-column ">

                                <div className="mt-3 d-flex flex-column">
                                    <span className="fw-bold"><img src={reportInfo.footer.doctor.signature} height={50} width={100} alt="firm doc" /></span>
                                    <span className="fw-bold">{reportInfo.footer.doctor.name}</span>
                                    <span className=""> {reportInfo.footer.doctor.position}</span>
                                    <span className=""> RM {reportInfo.footer.doctor.medical_record}</span>
                                </div>
                                <div className="d-flex flex-column justify-content-end pt-2 pb-2">
                                    <div className="d-flex justify-content-end pt-2 pb-2">
                                        <span className="fw-bold"> Teléfono: &nbsp;</span>{reportInfo.footer.appointment_phone}
                                        <span className="fw-bold"> &nbsp; | Email: &nbsp;</span>{reportInfo.footer.appointment_email}
                                    </div>
                                    <div className="d-flex justify-content-end pt-2 pb-2">
                                        <span className="fw-bold"> Dirección: &nbsp;</span>{reportInfo.footer.address}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div >
            </Modal >
        );

    };


    const render = () => {
        return (<>
            {renderReport()}
            <div className={"px-5 bodyRegisterInfoReading"}>
                <div className="px-2 my-3 ">
                    <div>
                        <h4 className="fw-bold text-secondary">{bodyValidationsRender.title}</h4>
                    </div>
                    <div>
                        {bodyValidationsRender.introduction?.enabled &&
                            <div
                                style={{ whiteSpace: "pre-wrap", border: "1px solid #6E6F7C09", backgroundColor: "#e9ecef", padding: "10px", borderRadius: "0.375rem" }}
                                dangerouslySetInnerHTML={{
                                    __html: dataSheet.introduction ?? "",
                                }}
                            />
                        }
                        {bodyValidationsRender.strategy?.enabled &&
                            renderStrategy()
                        }
                        {bodyValidationsRender.referredBy?.enabled &&
                            renderReferredBy()
                        }
                        {bodyValidationsRender.findings?.enabled &&
                            renderFindings()
                        }
                        {bodyValidationsRender.medicalConcept?.enabled &&
                            renderMedicalConcepts()
                        }
                        {bodyValidationsRender.conclusion?.enabled &&
                            renderConclusion()
                        }
                    </div>

                </div>

            </div>

            <div className="d-flex align-items-end h-100">
                <div className="controls">
                    <div className={"d-flex  w-100 align-items-center justify-content-between"}>
                        {(dataSheet.supportAudio !== null || dataSheet.supportAudio !== "") &&
                            <AudioPlayerComponent audio={dataSheet.supportAudio}
                                width={dataSheet.status === "generated_report" ? "100%" : undefined}
                            />

                        }
                        {
                            dataSheet.status !== "generated_report" &&
                            <div className=""
                                style={{ paddingLeft: 20 }}
                            >
                                <div className="d-flex w-100 justify-content-end">
                                    <Button variant="outline" className="mx-2"
                                        onClick={onOpenDetails}
                                    >
                                        Previsualizar <IconReportSearch className="mx-2" />
                                    </Button>
                                    <Button variant="secondary" className=""
                                        onClick={() => {
                                            handlerTranscription("end");
                                        }}
                                    >
                                        Guardar
                                    </Button>
                                </div>

                            </div>
                        }
                    </div>
                </div>
            </div>
        </>

        );
    };


    return render();
};

export default TranscriptionForm;
