import { RadioButton, SectionCard } from "@/components";
import SightTest from "../SightTest/SightTest";
import { OrthopticPayload, SightConfig } from "@/models/sheets/orthoptic";

interface IHirschbergReflex {
    orthopticPayload: OrthopticPayload;
    setOrthopticPayload: (bielschowskyPayload: OrthopticPayload) => void;
    onSubmit: () => void;
    isDisabledForm: boolean;
    handleOpenNoInsTextModal: ({ current, finishSelection }:{ current: string, finishSelection?: (value: string) => void }) => void

}

export default function HirschbergReflex({ orthopticPayload, setOrthopticPayload, onSubmit, isDisabledForm, handleOpenNoInsTextModal}: IHirschbergReflex) {

    const onChangeCorrection = (corrected: boolean | null) => {
        const options: OrthopticPayload = {
            ...orthopticPayload,
            hirschbergReflexes: {
                ...orthopticPayload.hirschbergReflexes,
                correction: corrected
            }
        };
        setOrthopticPayload(options);
        onSubmit();
    };

    const onChangeSightTest = (result: { vl?: SightConfig; vp?: SightConfig; }) => {
        const options: OrthopticPayload = {
            ...orthopticPayload,
            hirschbergReflexes: {
                ...orthopticPayload.hirschbergReflexes,
                ...result
            }
        };
        setOrthopticPayload(options);
        onSubmit();
    };

    const render = () => {
        return (
            <SectionCard>
                <div className="d-flex align-items-center">
                    <h5 className="flex-grow-1 text-secondary fw-bold">
                        Reflejo de Hirschberg
                    </h5>
                    <div className="d-flex align-items-center">
                        <RadioButton
                            className="me-5 fs-5"
                            disabled={isDisabledForm}
                            label="Sin corrección"
                            name="correctionHR"
                            id="withNoCorrectionHR"
                            onClick={() => {
                                onChangeCorrection(false);
                            }}
                            checked={orthopticPayload.hirschbergReflexes?.correction === false }
                        />
                        <RadioButton
                            disabled={isDisabledForm}
                            className="fs-5"
                            label="Con corrección"
                            name="correctionHR"
                            id="withCorrectionHR"
                            onClick={() => {
                                onChangeCorrection(true);
                            }}
                            checked={orthopticPayload.hirschbergReflexes?.correction === true}
                        />
                    </div>
                </div>
                <hr className="text-primary" />
                <div className="row">
                    <div className="col-lg-6">
                        <SightTest
                            isDisabledForm={isDisabledForm}
                            type="vl"
                            currentData={orthopticPayload.hirschbergReflexes?.vl}
                            onGetResult={(result) => onChangeSightTest(result)}
                            handleOpenNoInsTextModal={()=> (
                                !isDisabledForm && handleOpenNoInsTextModal({ current: "hirschberg_reflex_fv_obs", finishSelection: (value)=>{
                                    onChangeSightTest({vl: { observations: orthopticPayload?.hirschbergReflexes?.vl?.observations ?
                                        `${orthopticPayload?.hirschbergReflexes?.vl?.observations}\n${value}` : value}});
                                }})
                            )}
                        />
                    </div>
                    <div className="col-lg-6">
                        <SightTest
                            type="vp"
                            isDisabledForm={isDisabledForm}
                            currentData={orthopticPayload.hirschbergReflexes?.vp}
                            onGetResult={(result) => onChangeSightTest(result)}
                            handleOpenNoInsTextModal={()=> (
                                !isDisabledForm && handleOpenNoInsTextModal({ current: "hirschberg_reflex_cv_obs", finishSelection: (value)=>{
                                    onChangeSightTest({vp: { observations: orthopticPayload?.hirschbergReflexes?.vp?.observations ?
                                        `${orthopticPayload?.hirschbergReflexes?.vp?.observations}\n${value}` : value}});
                                }})
                            )}
                        />
                    </div>
                </div>
            </SectionCard>
        );
    };

    return render();
}