import { axiosInstance } from "@/config/axios";
import { IResponse } from "@/models";
import { DoctorsByContractListResponse, MedicalConceptsPayload, OrgansResponse, ResultsTranscriptionForm, RiskColorListResponse, StatusResponse, TranscriptionFormResponse, TranscriptionFormsParams, TranscriptionParams, TranscriptionReportParams, TranscriptionReportResponse, TranscriptionResponse, TranscriptionValidatePayload, TranscriptionValidationResponse, medicalConceptResponse } from "@/models/transcription/transcription";
import { setHeaders } from "@/utils";

export async function getTranscriptionService(params: TranscriptionParams): Promise<TranscriptionResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<TranscriptionResponse>(
        "/medical/clinicHistory/transcriptsByDoctor/", axiosParams
    );
    return serviceData;
}

export async function getStatusServices(params: { eaccount: number, view: string; }): Promise<StatusResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<StatusResponse>(
        "/medical/admissions/statusFilters/", axiosParams
    );
    return serviceData;
}

export async function getDoctorsByContractsListService(eaccount: number): Promise<DoctorsByContractListResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params: { eaccount } };

    const { data: serviceData } = await axiosInstance.get<DoctorsByContractListResponse>(
        "/medical/clinicHistory/doctorsWhoInterpreted/", axiosParams
    );
    return serviceData;
}

export async function getMedicalConceptsService(eaccount: number, searchValue: string, enabled: number, srvId: number): Promise<medicalConceptResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params: { eaccount, search: searchValue, enabled, srvId } };

    const { data: serviceData } = await axiosInstance.get<medicalConceptResponse>(
        "/medical/medicalConcept/", axiosParams
    );
    return serviceData;
}
export async function getOrgansService(eaccount: number): Promise<OrgansResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params: { eaccount, route: "oftalmico" } };

    const { data: serviceData } = await axiosInstance.get<OrgansResponse>(
        "/medical/generals/organs/", axiosParams
    );
    return serviceData;
}
export async function getRiskColorService(eaccount: number): Promise<RiskColorListResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params: { eaccount } };

    const { data: serviceData } = await axiosInstance.get<RiskColorListResponse>(
        "/medical/generals/medicalConceptRisk/", axiosParams
    );
    return serviceData;
}

export async function getTranscriptionFormService(params: TranscriptionFormsParams): Promise<TranscriptionFormResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<TranscriptionFormResponse>(
        "/medical/clinicHistory/transcription/", axiosParams
    );
    return serviceData;
}

export async function validateTranscriptionService(payload: TranscriptionValidatePayload): Promise<TranscriptionValidationResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.put<TranscriptionValidationResponse>(
        "/medical/clinicHistory/handlerTranscripts/", payload, axiosParams
        // "/medical/clinicHistory/startTranscription/", payload, axiosParams
    );
    return serviceData;
}

export async function updateTranscriptionService(payload: ResultsTranscriptionForm): Promise<IResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.put<IResponse>(
        "/medical/clinicHistory/transcription/", payload, axiosParams
    );
    return serviceData;
}
export async function deleteConceptsService(payload: ResultsTranscriptionForm): Promise<IResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, data: payload };


    const { data: serviceData } = await axiosInstance.delete<IResponse>(
        "/medical/clinicHistory/transcription/", axiosParams
    );
    return serviceData;
}

export async function getTranscriptionReportService(params: TranscriptionReportParams): Promise<TranscriptionReportResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<TranscriptionReportResponse>(
        "/medical/clinicHistory/detailsForTranscriptDownload/", axiosParams
    );
    return serviceData;
}

export async function updateMedicalConceptsService(payload: MedicalConceptsPayload): Promise<IResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.put<IResponse>(
        "/medical/clinicHistory/medicalConceptFromTranscriptions/", payload, axiosParams
    );
    return serviceData;
}

export async function deleteMedicalConceptsService(payload: {
    "id": number | null,
    "enabled": number;
}): Promise<IResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.patch<IResponse>(
        "/medical/clinicHistory/medicalConceptFromTranscriptions/", payload, axiosParams
    );
    return serviceData;
}
