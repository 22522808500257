import { Cie10EXTDiagnostic } from "@/models/historyDetails/adaptation";



export default function DiagnosticImpressionNewDetails({ data }: { data?: Cie10EXTDiagnostic; }) {

    const extDiagnostic = data;
    const renderComponent = () => {
        if (extDiagnostic && Object.values(extDiagnostic).length) {
            const mainExt = Object.values(extDiagnostic).find(item => item.isMain === 1);

            return (
                <div className="item py-2">
                    <table className="table table-bordered">
                        <thead>
                            <tr className="text-secondary">
                                <th>Fecha</th>
                                <th className="text-center">CIE-10</th>
                                <th>Impresión diagnóstica</th>
                                <th className="text-center">Ojo</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.values(extDiagnostic).map((item) => (
                                <tr key={`${item.id}-${item.clhId}`} style={{ backgroundColor: item.isMain ? "#fcfae6" : undefined }}>
                                    <td className="align-middle">{item.date}</td>
                                    <td align="center" className="align-middle">{item.cie10Code}</td>
                                    <td style={{ whiteSpace: "pre-line" }}>{item.cie10Description}</td>
                                    <td className="align-middle" align="center">{item.dbo?.name}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {mainExt && mainExt.evolution ? (
                        <h5>
                            <span className="fw-bold">Tiempo de evolución: </span>
                            {mainExt.evolution} {mainExt.evolutionTime?.name}
                        </h5>
                    ) : ""}
                </div>
            );
        } else {
            return <></>;
        }
    };


    return renderComponent();
}
