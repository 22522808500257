import { SectionCard, Select } from "@/components";
import { CommonSelect, OrthopticPayload } from "@/models/sheets/orthoptic";
import { getStereopsisListService } from "@/services/sheets/orthoptic";
import { useEffect, useState } from "react";

interface StereopsisParams {
    orthopticPayload: OrthopticPayload;
    setOrthopticPayload: (bielschowskyPayload: OrthopticPayload) => void;
    onSubmit: () => void;
    isDisabledForm: boolean;
    historySheet?: boolean;
    handleOpenNoInsTextModal: ({ current, finishSelection } : { current: string, finishSelection?: (value: string) => void }) => void
}

export default function Stereopsis({ orthopticPayload, setOrthopticPayload, onSubmit, isDisabledForm, historySheet, handleOpenNoInsTextModal }: StereopsisParams) {
    const [stereopsisList, setStereopsisList] = useState<CommonSelect[]>([]);

    useEffect(() => {
        async function fetchData() {
            if (!historySheet) {
                const response = await getStereopsisListService();
                if (response.results) setStereopsisList(response.results);
            }
        }
        fetchData();
    }, []);

    const onChangeStereopsis = (value: number) => {
        const options: typeof orthopticPayload = {
            ...orthopticPayload,
            stereopsis: {
                ...orthopticPayload.stereopsis,
                id: value
            }
        };
        setOrthopticPayload(options);
        onSubmit();
    };

    const onChangeObservations = (value: string) => {
        const options: typeof orthopticPayload = {
            ...orthopticPayload,
            stereopsis: {
                ...orthopticPayload.stereopsis,
                observations: value
            }
        };
        setOrthopticPayload(options);
        onSubmit();
    };

    const render = () => {
        const formattedStereopsisList = stereopsisList.map(
            item => ({ label: item.description, value: item.id })
        );

        const stereopsisListValue = formattedStereopsisList.find(
            item => item.value === orthopticPayload.stereopsis?.id
        );
        const stereopsisListValueInput = orthopticPayload.stereopsis?.test;

        return (
            <SectionCard className="h-100">
                <h5 className="text-secondary fw-bold align-self-start">
                    Estereopsis
                </h5>
                <hr className="text-primary mt-2 mb-4" />
                <div className=" h-100">
                    <div className="">
                        <div className="d-flex align-items-center justify-content-between">
                            <span className="me-3 text-secondary">Test</span>
                            <Select
                                disabled={isDisabledForm}
                                value={stereopsisListValue}
                                changeToInput={historySheet}
                                inputValue={stereopsisListValueInput}
                                options={formattedStereopsisList}
                                onChange={({ option }) => onChangeStereopsis(option.value)}
                                style={{ width: "100%" }}
                            />
                        </div>
                    </div>
                    <div className="mt-3 ">
                        <textarea
                            disabled={isDisabledForm}
                            className="form-control no-resize"
                            value={orthopticPayload.stereopsis?.observations}
                            onChange={({ target }) => onChangeObservations(target.value)}
                            placeholder="Observaciones..."
                            style={{ height: "150px" }}
                            onContextMenu={(event) => {
                                event.preventDefault();
                                !isDisabledForm && handleOpenNoInsTextModal({ current: "stereopsis_obs", finishSelection: (value)=>{
                                    onChangeObservations(orthopticPayload?.stereopsis?.observations ?
                                        `${orthopticPayload.stereopsis?.observations}\n${value}`: value);
                                }});
                            }}
                        />
                    </div>
                </div>
            </SectionCard>
        );
    };

    return render();
}